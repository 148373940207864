var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","order":"2","order-md":"1","md":"","align-self":"center"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","filled":"","rounded":"","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"auto","order-md":"2","align-self":"center"}},[_c('v-btn',{attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Sportangebot erstellen ")],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"fab":"","color":"primary","dark":"","fixed":"","bottom":"","right":"","large":""},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.angebote,"item-key":"name","fixed-header":"","search":_vm.search,"locale":"de-DE","footer-props":{
          itemsPerPageText: 'Zeilen pro Seite:',
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Es wurden keine Sportangebote gefunden. ")]},proxy:true},{key:"item.active",fn:function(ref){
        var index = ref.index;
        var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"primary"},on:{"change":function($event){return _vm.update_item(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.name",fn:function(ref){
        var index = ref.index;
        var item = ref.item;
return [_c('a',{staticClass:"font-weight-bold",class:item.active ? '' : 'text-decoration-line-through text--disabled',on:{"click":function($event){return _vm.open_link(("/verein/sportangebote/bearbeiten/" + (item.id)))}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.id",fn:function(ref){
        var index = ref.index;
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.open_link(("/verein/sportangebote/bearbeiten/" + (item.id)))}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-pen-to-square","size":"xl"}})],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.copy_item(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-copy","size":"xl"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }