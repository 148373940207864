<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="end">
          <v-col cols="12" order="2" order-md="1" md align-self="center">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              filled
              rounded
              label="Suche"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" order-md="2" align-self="center">
            <v-btn large rounded color="primary" @click="create()">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Sportangebot erstellen
            </v-btn>
          </v-col>
          
            <v-btn v-if="$vuetify.breakpoint.smAndDown" fab color="primary" dark fixed bottom right large @click="create()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="angebote"
          item-key="name"
          class="elevation-0"
          fixed-header
          :search="search"
          locale="de-DE"
          :footer-props="{
            itemsPerPageText: 'Zeilen pro Seite:',
          }"
        >
          <template v-slot:no-data>
            Es wurden keine Sportangebote gefunden.
          </template>
          <template v-slot:item.active="{ index, item }">
            <v-switch inset color="primary" v-model="item.active" @change="update_item(item)"></v-switch>
          </template>
          <template v-slot:item.name="{ index, item }">
            <a class="font-weight-bold" :class="item.active ? '' : 'text-decoration-line-through text--disabled'" @click="open_link(`/verein/sportangebote/bearbeiten/${item.id}`)">{{ item.name }}</a>
          </template>
          <template v-slot:item.id="{ index, item }">
            <v-btn icon @click="open_link(`/verein/sportangebote/bearbeiten/${item.id}`)"><font-awesome-icon icon="fa-duotone fa-pen-to-square" size="xl" /></v-btn>
            <v-btn class="ml-2" icon @click="copy_item(item)"><font-awesome-icon icon="fa-duotone fa-copy" size="xl" /></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import router from '../../../router'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true
})

export default {
  name: 'Verein',
  data() {
    return {
      selected: [],
      view: 'Eingereicht',
      search: '',
      headers: [
        {
          text: 'Aktiv',
          value: 'active',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Sportart',
          value: 'sportart.name',
          sortable: true,
        },
        {
          text: '',
          value: 'id',
          sortable: false,
        },
        
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
      nav: 'nav_expand'
    }),
    angebote(){
      if(this.user.data?.rechte?.admin){
        return this.sportangebote.all.filter(item => item.verein.id == this.user.data.verein?.id)
      }
      else if(this.user.data?.rechte?.abteilungsleiter){
        return this.sportangebote.all.filter(item => item.verein.id == this.user.data.verein?.id && user.data.rechte.sportarten.includes(item.sportart.name))
      }
      else if(this.user.data?.rechte?.trainer){
        return this.sportangebote.all.filter(item => item.verein.id == this.user.data.verein?.id && item.ansprechpartner.filter(person => person.id == this.user.uid)) 
      }
      return []
    },
  },
  mounted(){
    this.nav.title = 'Sportangebote'
    this.nav.subtitle = 'Übersicht'
    this.nav.show = true
    this.nav.back_link = ''
  },
  methods: {
    async copy_item(item){
      try {
        var neues_angebot = Object.assign({}, item)
        neues_angebot.name = "Kopie von "+item.name
        neues_angebot.active = false
        neues_angebot.verein = this.user.data.verein.id
        neues_angebot.sportart = item.sportart.name
        const docRef = await addDoc(collection(this.db, 'Sportangebote'), Object.assign({}, neues_angebot))
        Toast.fire({
          icon: 'success',
          title: 'Angebot wurde kopiert!'
        })
        this.unsaved_changes = false
        router.push('/verein/sportangebote/'+docRef.id)
      }
      catch(error){
        Toast.fire({
          icon: 'error',
          title: 'Beim Kopieren des Angebots ist ein Fehler aufgetreten!'
        })
      }
    },
    update_item(item){
      try {
        const angebotdoc = doc(this.db, 'Sportangebote', item.id)
        updateDoc(angebotdoc, { active: item.active })
        Toast.fire({
          icon: 'success',
          title: 'Erfolgreich gespeichert!'
        })
        this.unsaved_changes = false
      }
      catch(error){
        Toast.fire({
          icon: 'error',
          title: 'Beim speichern ist ein Fehler aufgetreten!'
        })
      }
    },
    open_link(link){
      router.push(link)
    },
    verein(id) {
      return this.vereine.data.find((verein) => verein.id === id)
    },
    async create() {
      const docRef = await addDoc(collection(this.db, 'Sportangebote'), {
        active: false,
        name: '',
        verein: this.user.data.verein.id,
        beschreibung: '',
        sportart: '',
        veranstaltungsort: {
            name: '',
            adresse: '',
            plz: '',
            ort: '',
        },
        altersgruppen: [],
        geschlecht: [],
        teilnehmerzahl: '',
        termin: { datum: '', beginn: '', ende: '' },
        termine: [{ datum: '', beginn: '', ende: '' }, { datum: '', beginn: '', ende: '' }],
        zeitraum: [
            {
                wochentag: '',
                datum: { von: '', bis: '' },
                uhrzeit: { beginn: '', ende: '' },
            },
        ],
        wiederholung: 'Einmaliger Termin',
        material: [],
        ansprechpartner: [
        {
            vorname: '',
            nachname: '',
            funktion: '',
            telefon: '',
            telefonpublic: false,
            whatsapp: false,
            email: '',
            emailvalid: false
        },
        ],
        fotos: [],
        sonstiges: '',
        created_at: serverTimestamp(),
        created: false,
      })
      router.push('/verein/sportangebote/bearbeiten/' + docRef.id)
    },
  },
}
</script>
